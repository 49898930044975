<template>
    <div class="d-flex flex-column h-100">
      <h4 class="my-3 d-block text-center">WEBRTC DEMO {{id}}</h4>
      <div class="flex-fill overflow-auto h-100 pb-4 px-4">
          <div class="h-100" v-if="index === 1">
              <h1 class="fw-800 text-muted m-0">Rooms</h1>
              <small class="text-muted">Click a user and start a conversation</small>
              <ul class="list-group mt-5" v-if="filtered_users.length">
                  <li class="list-group-item bg-dark cp d-flex flex-row justify-content-between align-items-center" @click="call(Object.keys(user)[0])" v-for="(user, i) in filtered_users" :key="i">
                    {{Object.keys(user)[0]+'-'+user[Object.keys(user)[0]]}}
                  </li>
              </ul>
              <div class="w-100" v-else>
                  <h6 class="my-2 text-muted">No online users</h6>
              </div>
          </div>
          <div class="h-100 w-100 position-relative" v-else-if="index === 2">
              <div class="overlay" v-if="!established">
                  <h5>{{call_to_id !== '' ? 'CALLING' : 'SOMEONE IS CALLING'}} ...</h5>
                  <button class="btn btn-danger" @click="endCall" v-if="call_to_id">Cancel</button>
                  <button class="btn btn-danger" @click="answerCall" v-else-if="call_to_id === '' && this.start" :disabled="answering">{{(answering ? 'Answering ...' : 'Answer')}}</button>
              </div>
              <div class="d-flex flex-column position-absolute" style="z-index: 999; bottom: 10px; left: 10px;" v-if="established">
                  <button class="btn btn-success mb-2" @click="sharescreen">SHARE SCREEN</button>
                  <button class="btn btn-dark mb-2" @click="nocam">TURN CAMERA {{(isNocam ? 'ON' : 'OFF')}}</button>
                  <button class="btn btn-secondary mb-2" @click="mute">{{(isMuted ? 'UNMUTE' : 'MUTE')}}</button>
                  <button class="btn btn-danger" @click="endCall">END CALL</button>
              </div>
              <video class="local-video" ref="localVideo" autoplay muted></video>
              <video class="remote-video" ref="remoteVideo" autoplay></video>
          </div>
          <div class="py-5" v-else>
              <h1 class="fw-800 text-muted m-0">Introduce yourself</h1>
              <small class="text-muted">Enter anything you want, so that we can recognize you.</small>
              
              <div class="form-group mt-5">
                  <input type="text" class="form-control form-control-lg text-muted bg-dark border-0" placeholder="" v-model="name"/>
                  <button class="btn btn-lg btn-primary px-5 my-4" @click="enter" v-if="isConnected">Enter</button>
                  <span class="my-2 text-muted" v-else>CONNECTING ..</span>
              </div>
          </div>
      </div>
      <div class="d-flex flex-row" style="height: 50px;">
        <img style="width: 50px;" class="mw-100" src="https://jlocodes.com/assets/jlocodes/tools/vue.png" />
        <img style="width: 50px;" class="mw-100" src="https://jlocodes.com/assets/jlocodes/tools/wrtc.png" />
        <img style="width: 50px;" class="mw-100" src="https://jlocodes.com/assets/jlocodes/tools/ws.png" />
        <img style="width: 50px;" class="mw-100 m-2" src="https://seeklogo.com/images/N/nodejs-logo-FBE122E377-seeklogo.com.png" />
        <h5 class="m-0 ml-auto mt-3 mr-3">Powered by: <a href="https://jlocodes.com">JLOCODES</a></h5>
      </div>
      <audio ref="audio" loop="true" hidden>
        <source src="./assets/audio.wav" type="audio/wav">
        <source src="./assets/audio.mp3" type="audio/mpeg">
        Your browser does not support the audio element.
    </audio>

    </div>
</template>

<script>

export default {
    data() {
        return {
            connection: {},
            index: 0,
            name: 'JL',
            users: [],
            call_to_id: '',
            call_from_id: '',

            isConnected: false,
            isMuted: false,
            isNocam: false,
            answering: false,
            start: false,
            isChannelReady: false,
            isInitiator: false,
            isStarted: false,
            localStream: undefined,
            remoteStream: undefined,
            established: false,
            pc: undefined,
            turnReady: false,
            remoteMute: false,
            pcConfig: {
                'iceServers': [
                    
                ]
            },
            sdpConstraints: {
                offerToReceiveAudio: true,
                offerToReceiveVideo: true
            },
            constraints: {
                audio: true,
                video: true
            },
            queue: [],
        }
    },
    computed: {
        id() {
            return '_' + Math.random().toString(36).substr(2, 9);
        },
        filtered_users() {
            let arr = [];
            if(this.users.length) {
                arr = this.users.filter((index) => {
                    if(Object.keys(index).length) {
                        return Object.keys(index)[0] !== this.id;
                    }
                }); 
            } 

            return arr;
        }
    },
    methods: {
        ws() {
            console.error("[STARTING CONNECTION TO WEBSOCKET] "+process.env.VUE_APP_URL)
            this.connection = new WebSocket(process.env.VUE_APP_URL);//new WebSocket(process.env.VUE_APP_URL);
            this.connection.onmessage = this.receive
            this.connection.onopen = this.open;
            this.connection.onclose = this.close;
        },
        open() {
            console.error("[CONNECTION ESTABLISHED]");
            this.isConnected = true;
        },
        close(event) {
            this.isConnected = false;
            throw('[CONNECTION CLOSED]'+event.code);
        },
        receive(event) {
              try {
                  var json = JSON.parse(event.data);
                  console.error('[RECEIVED MESSAGE]'+ json.type, this.isInitiator);
                  if(json.hasOwnProperty.call(json, 'type')) {
                      switch(json.type) {
                          case "room_refresh":
                              this.users = json.cl;
                              break;

                          case "receive_call":
                              this.play();
                              this.index = 2;
                              this.call_from_id = json.from;
                              this.call_to_id = '';
                              break;

                          case "end_call":
                              if(json.dc) {
                                  alert('User disconnected');
                              }

                              this.index = 1;
                              break;
                          
                          case "answer":
                          case "offer":
                              if(this.pc) {
                                  this.pc.setRemoteDescription(new RTCSessionDescription(json));
                              }
                              else this.queue.push(json);

                              if(json.type === 'answer') this.stop();
                              break;

                          case "candidate":
                              if(this.pc) {
                                  var candidate = new RTCIceCandidate({
                                      sdpMLineIndex: json.label,
                                      candidate: json.candidate
                                  });
                                  this.pc.addIceCandidate(candidate);
                              }
                              break;

                          default:
                              console.error('RECEIVED NOTHING', json);
                      }
                  }
              }
              catch(err) {
                  console.error(err);
              }
        },
        startVideo() {
            console.error('[STARTING VIDEO]');
            this.isInitiator = (this.call_to_id !== '');

            navigator.mediaDevices.getUserMedia({
                audio: true,
                video: true
            })
            .then(this.gotStream)
            .catch(function(e) {
                console.error('getUserMedia() error: ' + e);
            });
        },
        gotStream(stream) {
            this.start = true;
            console.error('[ADDING LOCAL STREAM]');
            this.$refs.localVideo.srcObject = stream;
            this.localStream = stream;
            this.maybeStart();
        },
        maybeStart() {
            console.error('[CHECKING MAYBE START]', this.isStarted);
            if (!this.isStarted && typeof this.localStream !== 'undefined') {
                console.error('[CREATING PEER CONNECTION]');
                this.createPeerConnection();
                this.pc.addStream(this.localStream);
                this.isStarted = true;
                if(this.isInitiator) {
                    this.doCall();
                }

                if(this.queue.length) {
                    this.queue.forEach(element => {
                        this.pc.setRemoteDescription(new RTCSessionDescription(element));
                    });
                    this.queue = [];
                }
            }
        },
        createPeerConnection() {
            try {
                this.pc = new RTCPeerConnection(this.pcConfig);
                this.pc.onicecandidate = this.handleIceCandidate;
                this.pc.onaddstream = this.handleRemoteStreamAdded;
                this.pc.ontrack = this.handleRemoteStreamRemoved;
                console.error('[CREATED PEER CONNECTION]');
            } 
            catch (e) {
                console.error('createPeerConnection() error: ' + e.message);
                return;
            }
        },
        handleIceCandidate(event) {
            console.error('[ICE CANDIDATE EVENT]');
            if (event.candidate) {
                this.send({
                            to: (this.isInitiator ? this.call_to_id : this.call_from_id),
                            session: {
                                type: 'candidate',
                                label: event.candidate.sdpMLineIndex,
                                id: event.candidate.sdpMid,
                                candidate: event.candidate.candidate
                            }
                        });
            } 
            else {
                this.established = true;
                this.answering = false;
                console.log('End of candidates.');
                console.error('[CONNECTION ESTABLISHED]');
            }
        },
        handleRemoteStreamAdded(event) {
            console.error('[REMOTE STREAM ADDED]');
            this.$refs.remoteVideo.srcObject = event.stream; //window.URL.createObjectURL(event.stream);
            this.remoteStream = event.stream;
        },
        handleRemoteStreamRemoved(event) {
            console.error('[REMOTE STREAM REMOVED]');
            if (!this.$refs.remoteVideo.srcObject) this.remoteStream = event.stream;
        },
        doCall() {
            console.error('[SENDING OFFER TO PEER]');
            this.pc.createOffer(this.setLocalAndSendMessage, this.handleCreateOfferError);
        },
        setLocalAndSendMessage(sessionDescription) {
            console.log('[SET LOCAL MESSAGE]', sessionDescription);
            this.pc.setLocalDescription(sessionDescription);
            this.send({to:(this.isInitiator ? this.call_to_id : this.call_from_id),session:sessionDescription});
        },
        handleCreateOfferError(event) {
            console.error('createOffer() error: ', event);
        },
        doAnswer() {
            console.error('[SENDING ANSWER TO PEER]');
            this.pc.createAnswer().then(
                this.setLocalAndSendMessage,
                this.onCreateSessionDescriptionError
            );
        },
        onCreateSessionDescriptionError(error) {
            console.error('onCreateSessionDescriptionError() error": ' + error.toString());
        },
        send(json = {}) {
            json['password'] = process.env.VUE_APP_PASSWORD;
            this.connection.send(JSON.stringify(json));
        },
        enter() {
            if(this.name.toString().length <= 0) {
                alert('Please provide your screen name');
                return;
            }

            this.index = 1;
            this.send({type:'join',name:this.name,id:this.id});
        },
        call(id) {
            this.index = 2;
            this.call_from_id = '';
            this.call_to_id = id;
            
            this.play();
            this.send({type:'call',to:this.call_to_id,from:this.id});
        },
        endCall() {
            this.send({type:'end_call',to:(this.isInitiator ? this.call_to_id : this.call_from_id),from:this.id});
        },
        answerCall() {
            this.answering = true;
            this.stop();
            this.doAnswer();
        },
        play() {
            this.$refs.audio.play();
        },
        stop() {
            this.$refs.audio.pause();
        },
        hangup() {
            this.index = 1;
            this.call_to_id = '';
            this.call_from_id = '';

            this.established = false;
            this.isMuted = false;
            this.isNocam = false;
            this.answering = false;

            if(this.localStream) {
                this.localStream.getAudioTracks()[0].enabled = true;
                this.localStream.getVideoTracks()[0].enabled = true;
                this.localStream.getTracks().forEach(function(track) {
                    console.log('local: '+track);
                    track.stop();
                });
                this.localStream = undefined;
            }

            if(this.remoteStream) {
                this.remoteStream.getTracks().forEach(function(track) {
                    console.log('remote: '+track);
                    track.stop();
                });
                this.remoteStream = undefined;
            }

            this.isChannelReady = false;
            this.isStarted = false;
            this.start = false;

            if(this.pc) {
                this.pc.close();
                this.pc = undefined;
            }

            console.error('[END TO END CONNECTION CLOSED]');
        },
        mute() {
            this.isMuted = !this.isMuted;
            this.localStream.getAudioTracks()[0].enabled = !this.localStream.getAudioTracks()[0].enabled;
        },
        nocam() {
            this.isNocam = !this.isNocam;
            this.localStream.getVideoTracks()[0].enabled = !(this.localStream.getVideoTracks()[0].enabled);
        },
        async sharescreen() {
            const gdmOptions = {
                  video: {
                    cursor: "always"
                  },
                  audio: true
              }

          let camstream = await navigator.mediaDevices.getDisplayMedia(gdmOptions)
                .catch(err => { 
                    console.error('getUserMedia() error: ' + err);
                 });

          if(!camstream) return;

          if(this.localStream) {
              this.localStream.getTracks().forEach(function(track) {
                  console.log('local: '+track);
                  track.stop();
              });

              this.localStream = camstream;
              this.$refs.localVideo.srcObject = this.localStream;
              let videoTrack = this.localStream.getVideoTracks()[0];
              this.pc.addStream(this.localStream);

              var sender = this.pc.getSenders().find(function(s) {
                  return s.track.kind == videoTrack.kind;
              });
              console.error('[FOUND SENDER]');
              sender.replaceTrack(videoTrack);
          }
      }
    },
    mounted() {
        this.ws();
        alert('You need to configure your turnIce Servers on pcConfig data');
    },  
    watch: {
        "index": function(n) {
            if(n === 2) {
                this.startVideo();
            }
            else if(n == 1) {
                this.call_to_id = '';
                this.stop();
                this.hangup();
            }
        }
    }
}
</script>

<style>
@import url('../node_modules/bootstrap/dist/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

html, body, #app {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #262B37 ;
}

.flex-1 {
    flex: 1;
}

.cp {
    cursor: pointer;
}

.fw-100 { font-weight: 100; }
.fw-200 { font-weight: 200; }
.fw-300 { font-weight: 300; }
.fw-400 { font-weight: 400; }
.fw-500 { font-weight: 500; }
.fw-600 { font-weight: 600; }
.fw-700 { font-weight: 700; }
.fw-800 { font-weight: 800; }
.fw-900 { font-weight: 900; }


.remote-video {
    width: 100%;
    height: 100%;
    background: #333846;
}

.local-video {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #3e4250;
    width: 30vw;
    height: 25vh;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
}
</style>
